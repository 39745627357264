.flag-select-content {
    background-color: var(--color-01);
    width: 60%;
    height: 100vh;
    position: fixed;
    z-index: 2;
    top: 0;
    right: -100%;
    transition: 850ms;
}

.flag-select-content.active {
    right: 0;
    transition: 350ms;
}

.flag-select-content ul {
    width: 100%;
}

.flag-select-content ul li {
    width: 100%;
    height: 7vh;
    line-height: 7vh;
}

.flag-select-content ul li a:link,
.flag-select-content ul li a:visited {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 0 5vw;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
}

.flag{
    width: 10vw;
    height: 8vw;
    margin: 0 5vw 0 0;
}

.close{
    width: 100%;
    height: 7vh;
    line-height: 7vh;
    font-size: 2rem;
    /* background-color: brown; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 5vw 0 0;
}