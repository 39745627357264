.menu-list-content {
    text-align: center;
    width: 100%;
    margin: 2vh auto;
}

.menu-list-content ul{
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    box-shadow: 1px 10px 10px 0 rgba(255,255,255,1);
    -webkit-box-shadow: 1px 10px 10px 0 rgba(255,255,255,1);
    -moz-box-shadow: 1px 10px 10px 0 rgba(255,255,255,1);
}

.menu-list-content ul li {
    display: inline-block;
    border-left: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 0.4rem 1rem;
    text-transform: uppercase;
    background-color: white;
}

.menu-list-content ul li:last-child {
    border-right: 1px solid black;
}

.menu-list-content ul li.selected{
    background-color: #2ECECA;
}

::-webkit-scrollbar { 
    display: none; 
}

.menu-list-content ul li a:link,
.menu-list-content ul li a:visited {
    text-decoration: none;
    font-weight: 500;
    color: black;
}

.menu-list-content ul li.selected a:link,
.menu-list-content ul li.selected a:visited {
    color: white;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}