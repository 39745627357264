.header-content {
    text-align: center;
}

.header-content a:link,
.header-content a:visited {
    color: black;
}

.header-content h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0 0 1vh 0;
}

.header-content h2 {
    font-size: 0.9rem;
    font-weight: 400;
}

.header-content img {
    object-fit: cover;
    width: 90%;
    height: 15vh;
    margin: 0 0 1vh 0;
}

.header-content-buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 1vh 0 2vh 0;
}

.header-content-buttons .flag {
    width: 37px;
    height: 24px;
    margin: 0;
    padding: 0;
}

.header-content-buttons .content {
    display: flex;
    width: 30%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
}