.button-list-content {
    text-align: center;
    width: 90%;
    margin: 2vh auto;
}

.button-list-content ul li{
    text-align: center;
    border: 1px solid black;
    padding: 2.2vh 0;
    margin: 2.2vh 0;
    text-transform: uppercase;
    border-radius: 14px;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
}

.button-list-content ul li a:link,
.button-list-content ul li a:visited {
    text-decoration: none;
    font-weight: 500;
    color: black;
    width: 100%;
    height: 100%;
}