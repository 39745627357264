.footer-container {
    margin: 4vh 0;
}

.footer-container p {
    font-size: 0.7rem;
}

.footer-container p span {
    font-weight: 700;
}