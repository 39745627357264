.menu-list-content {
    margin: 2vh auto;
}

.menu-list-content > h3 {
    font-size: 2.5rem !important;
    color: var(--color-01) !important;
    margin: 4vh 0 0 0 !important;
    font-family: 'allura', cursive !important;
}

.menu-list-content > div h3{
    font-size: 1.2rem;
    color: var(--color-02);
    margin: 2vh 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.menu-list-content > div.vins h3{
    font-size: 1.2rem;
    color: var(--color-03);
    margin: 2vh 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.menu-list-content h4 {
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0 0 2vh 0;
}

.menu-list-content h4 span {
    font-weight: 400;
    font-size: 2rem;
}

/* //////////////// DISH //// */
/* //////////////// DISH //// */

.dish-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid #dadada;
    width: 90%;
    margin: 0 auto 1vh auto;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
    padding: 0.4rem 0.6rem;

}

.dish-content p {
    margin: 0.2rem 0;
}

.dish-content .gauche {
    text-align: left;
}

.dish-content .gauche .title {
    font-size: 1rem;
    color: var(--color-02);
}

.dish-content .gauche .tags {
    font-size: 0.8rem;
}

.dish-content .droite {
    display: flex;
    flex-direction: column;
}

.dish-content .droite img {
    width: 8vh;
    height: 8vh;
    object-fit: cover;
}

.dish-content .droite .price {
    font-size: 1rem;
    background-color: var(--color-02);
    color: white;
    font-weight: 700;
    padding: 0.3rem;
}

/* //////////////// LIGHTBOX //// */

.image-card {
  width: 30vw;
  height: 30vw;
  object-fit: cover;
}

#lightbox-img {
  width: 100%;
  object-fit: cover;
}

#lightbox {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-02);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* //////////////// DRINK //// */
/* //////////////// DRINK //// */

.drink-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid #dadada;
    width: 90%;
    margin: 0 auto 1vh auto;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
    padding: 0.4rem 0.6rem;
}

.drink-content p {
    margin: 0.2rem 0;
}

.drink-content .gauche {
    text-align: left;
}

.drink-content .gauche .title {
    font-size: 1rem;
    color: var(--color-02);
}

.drink-content .gauche .tags {
    font-size: 0.8rem;
}

.drink-content .gauche div.specs-loop {
    /* background-color: aqua; */
    margin: 1vh 0 0 0;
}

.drink-content .droite {
    display: flex;
    flex-direction: column;
}

.drink-content .droite img {
    width: 8vh;
    height: 8vh;
    object-fit: cover;
}

.drink-content .droite .price {
    font-size: 1rem;
    background-color: var(--color-02);
    color: white;
    font-weight: 700;
    padding: 0.3rem;
}

/* //////////////// WINE //// */
/* //////////////// WINE //// */

.wine-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid #dadada;
    width: 90%;
    margin: 0 auto 1vh auto;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
    padding: 0.4rem 0.6rem;
}

.wine-content p {
    margin: 0.2rem 0;
}

.wine-content .gauche {
    text-align: left;
    padding: 0 0.5rem 0 0;
}

.wine-content .gauche .title {
    font-size: 1rem;
    color: var(--color-03);
}

.wine-content .gauche .tags {
    font-size: 0.8rem;
}

.wine-content .gauche div.specs-loop {
    /* background-color: aqua; */
    margin: 1vh 0 0 0;
}

.wine-content .droite {
    display: flex;
    flex-direction: column;
}

.wine-content .droite img {
    width: 8vh;
    height: 8vh;
    object-fit: cover;
}

.wine-content .droite .price {
    font-size: 1rem;
    background-color: var(--color-03);
    color: white;
    font-weight: 700;
    padding: 0.3rem;
}